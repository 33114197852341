const DoTestPopup = ({ isShow, lessonId }) => {
    const [testExam, setTestExam] = useState();
    const [question, setQuestion] = useState();
    const [listAnswer, setListAnswer] = useState([]);

    useEffect(() => {
        $('#testPopup').modal({
            backdrop: 'static',
            keyboard: false,
            focus: true,
        });
    }, []);

    useEffect(() => {
        if (isShow) {
            $('#testPopup').modal('show');
        } else {
            $('#testPopup').modal('hide');
        }
    }, [isShow]);

    const fetchData = async () => {
        const { status, data } = await _testApis.getTest({ lessonId });
        if (status === 200) {
            setTestExam(data.test);
            setQuestion(data.question);
            setListAnswer(data.listAnswer);
        }
    };

    const showQuestion = () => {
        if (!question) return null;
        return <p className="lesson-slide-box">{question.tq_content}</p>;
    };

    const showAnswer = () => {
        if (listAnswer.length == 0) return null;
        let xhtml = listAnswer.map((o, index) => {
            return <li key={index}>{o.ta_content}</li>;
        });
        return <ul className="list-answer">{xhtml}</ul>;
    };

    useEffect(() => {
        if (lessonId) {
            fetchData();
        }
    }, [lessonId]);

    return (
        <div>
            <div
                className="modal fade"
                id="testPopup"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="testPopup"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="testPopup">
                                {testExam?.t_name}
                            </h5>
                        </div>
                        <div className="modal-body">
                            {showQuestion()}
                            {showAnswer()}
                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button> */}
                            <button type="button" className="btn btn-primary">
                                Trả lời
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
