const RateFormCommit = ({ onCommit, isReset }) => {
    useEffect(() => {
        const form_name = '#ratecomment_form';
        if ($(form_name).length > 0) {
            var fv = $(form_name)
                .formValidation({
                    framework: 'bootstrap',
                    err: {
                        /* container: 'tooltip'*/
                    },
                    icon: {
                        //valid: 'fa fa-check',
                        //invalid: 'fa fa-times',
                        //validating: 'fa fa-refresh'
                    },
                    fields: {
                        rate: {
                            message: 'Bạn chưa đánh giá bài học',
                            validators: {
                                notEmpty: {
                                    message: 'Bạn chưa đánh giá bài học',
                                },
                            },
                        },
                        content: {
                            message: 'Bạn cần nhập nội dung bình luận',
                            validators: {
                                notEmpty: {
                                    message: 'Bạn cần nhập nội dung bình luận',
                                },
                            },
                        },
                    },
                })
                .on('success.form.fv', function (e) {
                    e.preventDefault();

                    const dataPost = {
                        ratePoint: $(`${form_name} #star-rating`).val(),
                        comment: $(`${form_name} #content`).val(),
                    };

                    if (onCommit) {
                        onCommit(dataPost);
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (isReset) {
            const form_name = '#ratecomment_form';
            $(form_name).data('formValidation').resetForm();
        }
    }, [isReset]);
    return (
        <Fragment>
            <form
                id="ratecomment_form"
                className="comment-one__form contact-form-validated"
                noValidate="novalidate"
            >
                <div className="row">
                    <div className="col-xl-12">
                        <div className="comment-form__input-box form-group">
                            <Rating
                                className="star-rating form-control"
                                name="rate"
                                id="star-rating"
                            ></Rating>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="comment-form__input-box form-group">
                            <textarea
                                cols="30"
                                rows="4"
                                name="content"
                                id="content"
                                placeholder="Nhập nội dung..."
                                className="form-control"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="comment-form__btn-box">
                            <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                            >
                                GỬI ĐÁNH GIÁ & BÌNH LUẬN
                                <i className="fa fa-angle-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};
