const ActiveCourseForm = ({ courseId }) => {
    useEffect(() => {
        const form_name = '#active_course_form';
        if ($(form_name).length > 0) {
            var fv = $(form_name)
                .formValidation({
                    framework: 'bootstrap',
                    err: {},
                    icon: {},
                    fields: {
                        form_code: {
                            validators: {
                                notEmpty: {
                                    message: 'Mã kích hoạt không được để trống',
                                },
                            },
                        },
                    },
                })
                .on('success.form.fv', function (e) {
                    e.preventDefault();

                    var dataPost = {
                        code: $(`${form_name} #form_code`).val(),
                        courseId,
                    };

                    swalShowLoading('<p>Đang kích hoạt khóa học ...</p>');

                    _courseApis
                        .activeCourse(dataPost)
                        .then(({ status, data }) => {
                            if (status !== 200) {
                                $(form_name).data('formValidation').resetForm();
                                mySwal(SWAL_CONST.TYPE.ERR, data.msg);
                                return;
                            }
                            const ridirectToFirstLesson = () => {
                                window.location.href = data.firstUrl;
                            };

                            mySwalShowTimer({
                                type: SWAL_CONST.TYPE.SUCCESS,
                                title: 'Đã kích hoạt khóa học thành công',
                                msg: `<p>Hệ thống tự động chuyển sang bài học đầu tiên trong <strong></strong> giây nữa</p>`,
                                func: ridirectToFirstLesson,
                                timer: 5000,
                            });
                        });
                });
        }
    }, []);
    return (
        <div className="lesson-slide-box">
            <form
                id="active_course_form"
                name="active_course_form"
                className="comment-one__form contact-form-validated"
            >
                <input
                    type="hidden"
                    id="activeCourseId"
                    defaultValue="@Model.CourseOnline.co_id"
                />
                <div className="row mb-30">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input
                                name="form_code"
                                id="form_code"
                                type="text"
                                placeholder="Vui lòng nhập mã kích hoạt"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="comment-form__btn-box form-group text-center">
                    <button
                        type="submit"
                        className="thm-btn comment-form__btn text-center"
                    >
                        Kích hoạt
                        <i className="fa fa-angle-right" />
                    </button>
                </div>
            </form>
        </div>
    );
};
document
    .querySelectorAll('react-active-course-form')
    .forEach((domContainer) => {
        const courseId = parseInt(domContainer.dataset.courseid, 10);
        const root = ReactDOM.createRoot(domContainer);
        root.render(creElm(ActiveCourseForm, { courseId: courseId }));
    });
