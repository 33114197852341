const ContactForm = () => {
    useEffect(() => {
        const form_name = '#contact_form';
        if ($(form_name).length > 0) {
            var fv = $(form_name)
                .formValidation({
                    framework: 'bootstrap',
                    err: {
                        /* container: 'tooltip'*/
                    },
                    icon: {
                        //valid: 'fa fa-check',
                        //invalid: 'fa fa-times',
                        //validating: 'fa fa-refresh'
                    },
                    fields: {
                        name: {
                            validators: {
                                notEmpty: {
                                    message: 'Tên không được để trống',
                                },
                            },
                        },
                        email: {
                            validators: {
                                notEmpty: {
                                    message:
                                        'Vui lòng nhập email để chúng tôi có thể phản hồi bạn sớm nhất',
                                },
                                emailAddress: {
                                    message:
                                        'Vui lòng nhập email để chúng tôi có thể phản hồi bạn sớm nhất',
                                },
                            },
                        },
                        phone: {
                            validators: {
                                notEmpty: {
                                    message:
                                        'Vui lòng nhập số điện thoại để chúng tôi có thể phản hồi bạn sớm nhất',
                                },
                            },
                        },
                        message: {
                            validators: {
                                notEmpty: {
                                    message: 'Nội dung không được để trống',
                                },
                            },
                        },
                    },
                })
                .on('success.form.fv', function (e) {
                    e.preventDefault();

                    var dataPost = {
                        cu_name: $(`${form_name} #name`).val(),
                        cu_phone: $(`${form_name} #phone`).val(),
                        cu_email: $(`${form_name} #email`).val(),
                        cu_message: $(`${form_name} #message`).val(),
                    };

                    swalShowLoading('<p>Đang xử lý</p>');

                    _contactApis.submit(dataPost).then(({ status, data }) => {
                        $(form_name).data('formValidation').resetForm();
                        if (status !== 200) {
                            mySwal(SWAL_CONST.TYPE.WARNING, data.msg);
                            return;
                        }

                        $(form_name).trigger('reset');
                        mySwal(
                            SWAL_CONST.TYPE.SUCCESS,
                            'Bạn đã gửi liên hệ thành công! Chúng tôi sẽ liên hệ sớm nhất với bạn khi có thể'
                        );
                    });
                });
        }
    }, []);
    return (
        <div>
            <form
                className="comment-one__form contact-form-validated"
                noValidate="novalidate"
                id="contact_form"
            >
                <div className="row">
                    <div className="col-xl-12">
                        <div className="comment-form__input-box form-group">
                            <input
                                type="text"
                                placeholder="Họ tên"
                                name="name"
                                id="name"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="comment-form__input-box form-group">
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                id="email"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="comment-form__input-box  form-group">
                            <input
                                type="text"
                                placeholder="Điện thoại"
                                name="phone"
                                id="phone"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="comment-form__input-box text-message-box form-group">
                            <textarea
                                name="message"
                                placeholder="Nội dung"
                                id="message"
                                className="form-control"
                            />
                        </div>
                        <div className="comment-form__btn-box">
                            <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                            >
                                GỬI Ý KIẾN <i className="fa fa-angle-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

document.querySelectorAll('react-contact-form').forEach((domContainer) => {
    const root = ReactDOM.createRoot(domContainer);
    root.render(creElm(ContactForm, {}));
});
