const RateLastGroupForm = ({
    dataItem,
    onCommentSuccess,
    lessonId,
    courseId,
}) => {
    const [isShow, setIsShow] = useState(false);
    const [isResetForm, setIsResetForm] = useState(0);
    useEffect(() => {
        if (!_.isEmpty(dataItem)) {
            const { isLastOfGroup, isRateLesson } = dataItem;
            if (isLastOfGroup && !isRateLesson) {
                setIsShow(true);
                return;
            }
            setIsShow(false);
            return;
        }
    }, [dataItem]);

    const onCommit = (dataPost) => {
        dataPost = {
            ...dataPost,
            lessonId,
            courseId,
        };
        swalShowLoading('Đang gửi bình luận...');

        _lessonApis.rateLesson(dataPost).then(({ status, data }) => {
            if (status !== 200) {
                mySwal(
                    SWAL_CONST.TYPE.ERR,
                    `Đã có lỗi xảy ra! Xin vui lòng thực hiện lại`
                );
                setIsResetForm((_value) => {
                    return (_value += 1);
                });
                return;
            }
            mySwalTimer(
                SWAL_CONST.TYPE.SUCCESS,
                `Bạn đã bình luận thành công`,
                1500
            );
            if (onCommentSuccess) {
                onCommentSuccess();
            }
        });
    };
    return (
        <Fragment>
            {isShow ? (
                <Fragment>
                    <div className="mb-30"></div>
                    <p className="lesson-slide-box text-center">
                        Xin vui lòng <b>đánh giá</b> và <b>bình luận</b> để có
                        thể hoàn thành bài học này
                    </p>
                    <RateFormCommit
                        onCommit={onCommit}
                        isReset={isResetForm}
                    ></RateFormCommit>
                </Fragment>
            ) : null}
        </Fragment>
    );
};
