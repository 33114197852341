const StudyStartButton = ({ courseId, courseName }) => {
    const [buttonInfo, setButtonInfo] = useState();

    const fetchData = async () => {
        const dataPost = {
            courseId,
        };
        const { status, data } = await _courseApis.getStudyStartButton(
            dataPost
        );
        if (status !== 200) {
            setButtonInfo();
            return;
        }
        setButtonInfo(data.data);
    };

    useEffect(() => {
        if (courseId) {
            fetchData();
        }
    }, [courseId]);

    const showHtml = () => {
        let xhtml = null;
        if (!buttonInfo) {
            return xhtml;
        }
        if (!buttonInfo.isAuth) {
            return (
                <p className="lesson-slide-box">
                    Xin vui lòng{' '}
                    <b>
                        <a href="/dang-nhap">Đăng nhập</a>
                    </b>
                </p>
            );
        }
        switch (buttonInfo.courseStatus) {
            case CUSTOMER_COURSE_STATUS.CHUA_DANG_KY: {
                xhtml = (
                    <RegisterCourseButton
                        courseId={courseId}
                        courseName={courseName}
                    />
                );
                break;
            }
            case CUSTOMER_COURSE_STATUS.VUA_TAO: {
                xhtml = (
                    <p className="lesson-slide-box">
                        Bạn đã đăng ký khóa học nhưng chưa được cấp mã kích
                        hoạt.Xin vui lòng liên hệ với ban quản trị để biết thêm
                        thông tin chi tiết
                    </p>
                );
                break;
            }
            case CUSTOMER_COURSE_STATUS.DA_CAP_MA: {
                xhtml = (
                    <a
                        className="thm-btn comment-form__btn"
                        href={`/kich-hoat-khoa-hoc/${courseId}`}
                    >
                        KÍCH HOẠT KHÓA HỌC
                        <i className="fa fa-angle-right"></i>
                    </a>
                );
                break;
            }
            case CUSTOMER_COURSE_STATUS.DA_KICH_HOAT:
            case CUSTOMER_COURSE_STATUS.DANG_HOC:
            case CUSTOMER_COURSE_STATUS.HOAN_THANH: {
                xhtml = (
                    <a
                        className="thm-btn comment-form__btn"
                        href={buttonInfo.currentLink}
                    >
                        BẮT ĐẦU HỌC
                        <i className="fa fa-angle-right"></i>
                    </a>
                );
                break;
            }
            case CUSTOMER_COURSE_STATUS.KHONG_HOAN_THANH: {
                xhtml = (
                    <p className="lesson-slide-box">
                        Bạn đã không hoàn thành khóa học
                    </p>
                );
                return;
            }
            case CUSTOMER_COURSE_STATUS.HUY_DANG_KY: {
                xhtml = (
                    <p className="lesson-slide-box">
                        Đăng ký khóa học của bạn bị từ chối. Xin vui lòng liên
                        hệ với ban quản trị để biết thêm thông tin chi tiết
                    </p>
                );
                return;
            }
        }

        return xhtml;
    };

    return (
        <Fragment>
            <div className="comment-form text-center">{showHtml()}</div>
        </Fragment>
    );
};

document
    .querySelectorAll('react-study-start-button')
    .forEach((domContainer) => {
        const courseName = domContainer.dataset.coursename;
        const courseId = parseInt(domContainer.dataset.courseid, 10);
        const root = ReactDOM.createRoot(domContainer);
        root.render(creElm(StudyStartButton, { courseId, courseName }));
    });
