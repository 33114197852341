const StudyLessonMenuItem = ({ dataItem, courseItem, customerCourseItem }) => {
    const [activeClass, setActiveClass] = useState('');
    const {
        cco_listlessoncompleted,
        cco_latestlessoncompleted: currentLessonId,
    } = customerCourseItem;
    const listLessonComplete = cco_listlessoncompleted
        ? JSON.parse(cco_listlessoncompleted)
        : [];
    useEffect(() => {
        if ($('.accrodion-grp').length) {
            var accrodionGrp = $('.accrodion-grp');
            accrodionGrp.each(function () {
                var accrodionName = $(this).data('grp-name');
                var Self = $(this);
                var accordion = Self.find('.accrodion');
                Self.addClass(accrodionName);
                Self.find('.accrodion .accrodion-content').hide();
                Self.find('.accrodion.active')
                    .find('.accrodion-content')
                    .show();
                accordion.each(function () {
                    $(this)
                        .find('.accrodion-title')
                        .on('click', function () {
                            if ($(this).parent().hasClass('active') === false) {
                                $('.accrodion-grp.' + accrodionName)
                                    .find('.accrodion')
                                    .removeClass('active');
                                $('.accrodion-grp.' + accrodionName)
                                    .find('.accrodion')
                                    .find('.accrodion-content')
                                    .slideUp();
                                $(this).parent().addClass('active');
                                $(this)
                                    .parent()
                                    .find('.accrodion-content')
                                    .slideDown();
                            }
                        });
                });
            });
        }
    }, [activeClass]);

    useEffect(() => {
        if (!_.isEmpty(customerCourseItem)) {
            const itemCheck = dataItem.lessonList.find(
                (o) => o.l_id === currentLessonId
            );
            if (itemCheck) {
                setActiveClass('active');
            }
        }
    }, [customerCourseItem]);

    const showItem = (_lessonItem) => {
        if (_lessonItem.l_id === currentLessonId) {
            return (
                <li className="active">
                    <a
                        href={`/hoc-truc-tuyen/${courseItem.co_code}/${_lessonItem.l_id}`}
                    >
                        <span className="fa fa-circle" />
                        {_lessonItem.l_name}
                    </a>
                </li>
            );
        }
        if (_.includes(listLessonComplete, _lessonItem.l_id)) {
            return (
                <li className="finish">
                    <a
                        href={`/hoc-truc-tuyen/${courseItem.co_code}/${_lessonItem.l_id}`}
                    >
                        <span className="fa fa-check" />
                        {_lessonItem.l_name}
                    </a>
                </li>
            );
        }
        return (
            <li className="">
                <a href="#">
                    <span className="fa fa-lock" />
                    {_lessonItem.l_name}
                </a>
            </li>
        );
    };
    const showHtml = () => {
        let xhtml = null;
        if (dataItem && dataItem.lessonList.length > 0) {
            xhtml = dataItem.lessonList.map((item, index) => {
                return <Fragment key={index}>{showItem(item)}</Fragment>;
            });
        }
        return xhtml;
    };
    return (
        <div className={`accrodion ${activeClass}`}>
            <div className="accrodion-title">
                <h4>{dataItem.lessonGroup.lg_name}</h4>
            </div>
            <div className="accrodion-content ">
                <div className="inner">
                    <ul className="sidebar__category-list list-unstyled">
                        {showHtml()}
                    </ul>
                </div>
            </div>
        </div>
    );
};
