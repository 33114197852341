const RegisterCourseButton = ({ courseId, courseName }) => {
    const onRegister = async () => {
        const swalRes = await mySwal(
            SWAL_CONST.TYPE.QUESTION,
            `<p>Bạn có muốn đăng ký khóa học <b>[${courseName}]</b> không?</p>`,
            true
        );
        if (!swalRes.value) {
            return;
        }
        swalShowLoading('Đang thực hiện đăng ký...');
        const { status, data } = await _courseApis.registerCourse({ courseId });
        if (status !== 200) {
            mySwal(SWAL_CONST.TYPE.ERR, data.msg);
            return;
        }

        const ridirectToActivePage = () => {
            window.location.href = `/kich-hoat-khoa-hoc/${courseId}`;
        };

        mySwalShowTimer({
            type: SWAL_CONST.TYPE.SUCCESS,
            msg: '<p>Đăng ký thành công !</p><p>Hệ thống sẽ tự động chuyển sang trang <b>Kích hoạt khóa học</b> trong <strong></strong> giây nữa</strong></p>',
            timer: 5000,
            func: ridirectToActivePage,
        });
    };
    return (
        <button className="thm-btn comment-form__btn" onClick={onRegister}>
            ĐĂNG KÝ HỌC
            <i className="fa fa-angle-right"></i>
        </button>
    );
};
