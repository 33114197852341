const RegisterCoursePopup = ({ courseId, isShow }) => {
  useEffect(() => {
    if (isShow) {
      $("#courseOnlineRegister").modal("show");
    }
  }, [isShow]);
  return (
    <div
      class="modal fade"
      id="courseOnlineRegister"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content p-30 pt-10">
          <h3 class="text-center text-theme-colored2 mb-20">
            THÔNG TIN ĐĂNG KÝ
          </h3>
          {/* @if (Model.CourseOnline.co_tuition == 0)
          {
              <div class="tab-pane" id="tab4">
                  @if(Model.CustomerCategory != null)
                  {
                      @Html.Raw(Model.CustomerCategory.cc_coursefreecontent)
                  }
                 
                  @if (Model.IsAuth)
                  {
                      <h4 class="mt-20 mb-15">Đường dẫn bài viết facebook của bạn (Không bắt buộc)</h4>
                      <form id="register_free_course" name="register_free_course" method="post" enctype="multipart/form-data">
                          <input type="hidden" value="@Model.CourseOnline.co_id" id="form_courseId" />
                          <div class="row">
                              <div class="col-sm-12">
                                  <div class="form-group">
                                      <input name="form_link" id="form_link" type="text" placeholder="" class="form-control">
                                  </div>
                              </div>
                          </div>
                          <div class="form-group">
                              <button class="btn btn-block btn-theme-colored2 mt-20" type="submit">ĐĂNG KÝ</button>
                          </div>
                      </form>
                  }
                  else
                  {
                      <p class="mt-20 mb-15">Xin vui lòng <b><a href="/dang-nhap">Đăng Nhập</a></b> để thực hiện tính năng đăng ký </p>
                  }
              </div>
          }
          else
          {
              <div class="tab-pane" id="tab5">
                  <h4 class="mt-20 mb-15">Chi phí khóa học : <strong>@String.Format("{0:0,0 VNĐ}", Model.CourseOnline.co_tuition)</strong></h4>
                  
                  <ul class="list-icon rounded media-list">
                       <li>
                          @if (Model.IsAuth)
                          {
                              <div class="form-group">
                                  <a class="btn btn-block btn-theme-colored2 mt-20" onclick="doRegisterFeeCourse(@Model.CourseOnline.co_id)">ĐĂNG KÝ</a>
                              </div>
                          }
                          else
                          {
                              <p class="mt-20 mb-15">Xin vui lòng <b><a href="/dang-nhap">Đăng Nhập</a></b> để thực hiện tính năng đăng ký </p>
                          }

                      </li>
                  </ul>
              </div>
          } */}
        </div>
      </div>
    </div>
  );
};
