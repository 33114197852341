const creElm = React.createElement;
const { useEffect, useState, Fragment, useRef } = React;

/**
 * Template - Khi cần truyền props từ website => component
 */
// document
//   .querySelectorAll("react-customer-login-form")
//   .forEach((domContainer) => {
//     // Read the comment ID from a data-* attribute.
//     // const commentID = parseInt(domContainer.dataset.commentid, 10);
//     const root = ReactDOM.createRoot(domContainer);
//     root.render(creElm(CustomerLoginForm, {}));
//   });
