const LessonFooter = ({ courseId, lessonId }) => {
    const [infoDetail, setInfoDetail] = useState({});
    const [reloadCount, setReloadCound] = useState(0);

    const fetchData = async () => {
        const { status, data } = await _lessonApis.getDirectGroupButton({
            courseId,
            lessonId,
        });
        if (status !== 200) {
            setInfoDetail({});
            return;
        }
        setInfoDetail(data.data);
    };
    useEffect(() => {
        if (courseId && lessonId) {
            fetchData();
        }
    }, [courseId, lessonId]);

    const onCommentSuccess = () => {
        fetchData();
        setReloadCound((_value) => {
            return (_value += 1);
        });
    };

    return (
        <Fragment>
            <CourseDirectGroupButton
                dataItem={infoDetail}
                lessonId={lessonId}
            />
            <RateLastGroupForm
                dataItem={infoDetail}
                onCommentSuccess={onCommentSuccess}
                lessonId={lessonId}
                courseId={courseId}
            />

            <CourseProgressInfo courseId={courseId} />

            <LessonCommentList
                dataItem={infoDetail}
                lessonId={lessonId}
                reloadCount={reloadCount}
            />
        </Fragment>
    );
};

document.querySelectorAll('react-lesson-footer').forEach((domContainer) => {
    const courseId = parseInt(domContainer.dataset.courseid, 10);
    const lessonId = parseInt(domContainer.dataset.lessonid, 10);
    const root = ReactDOM.createRoot(domContainer);
    root.render(creElm(LessonFooter, { courseId, lessonId }));
});
