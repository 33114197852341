const LessonCommentList = ({ lessonId, reloadCount, dataItem }) => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);

    const resetValue = () => {
        setList([]);
        setTotal(0);
    };
    const fetchData = async () => {
        const { status, data } = await _lessonApis.getListRateComment({
            lessonId,
        });
        if (status !== 200) {
            resetValue();
            return;
        }
        setList(data.data.list);
        setTotal(data.data.total);
    };

    useEffect(() => {
        if ((lessonId || reloadCount) && dataItem.isLastOfGroup) {
            fetchData();
        }
    }, [lessonId, reloadCount, dataItem]);

    const showRate = (ratePoint) => {
        let xhtml = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= ratePoint) {
                xhtml.push(<span class="fa fa-star checked"></span>);
            } else {
                xhtml.push(<span class="fa fa-star"></span>);
            }
        }
        return xhtml;
    };

    const showHtml = () => {
        let xhtml = null;

        if (list.length > 0) {
            xhtml = list.map((o, index) => {
                console.log(o);
                return (
                    <div className="comment-one__single" key={index}>
                        <div className="comment-one__image">
                            <img
                                src={
                                    o.c_avatar ? o.c_avatar : '/images/user.png'
                                }
                                alt=""
                            />
                        </div>

                        <div className="comment-one__content">
                            <h3>{o.c_name}</h3>
                            <p className="comment_rate">
                                {showRate(o.lr_rate)}
                            </p>
                            <p>{o.lr_content}</p>
                        </div>
                    </div>
                );
            });
        }

        return xhtml;
    };

    return (
        <Fragment>
            {dataItem?.isLastOfGroup ? (
                <div className="course-details__bottom">
                    <div className="comment-one">
                        <h3 className="comment-one__title">
                            {total > 0
                                ? `${total} Bình luận`
                                : 'Chưa có bình luận nào'}{' '}
                        </h3>
                        {showHtml()}
                    </div>
                </div>
            ) : null}{' '}
        </Fragment>
    );
};
