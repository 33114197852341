const CoursePrevButton = ({ link }) => {
    const showHtml = () => {
        let xhtml = null;
        if (link) {
            return (
                <a
                    href={link}
                    className="thm-btn comment-form__btn btn-reverse"
                >
                    <i className="fa fa-angle-left" />
                    QUAY LẠI
                </a>
            );
        }
        return xhtml;
    };
    return <Fragment>{showHtml()}</Fragment>;
};
