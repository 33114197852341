const BirthYearSelect = (props) => {
  const showHtml = () => {
    let xhtml = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= 1900; i--) {
      xhtml.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return xhtml;
  };
  return (
    <div className="comment-form__input-box form-group">
      <label>Năm sinh</label>
      <select className="form-control" {...props}>
        {showHtml()}
      </select>
    </div>
  );
};
