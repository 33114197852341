function StudyLessonMenu({ courseId }) {
    const [listMenu, setListMenu] = useState([]);
    const [course, setCourse] = useState({});
    const [customerCourse, setCustomerCourse] = useState({});

    const fetchData = async () => {
        const { status, data } = await _lessonApis.getStudyLessonMenu({
            courseId,
        });
        if (status !== 200) return;

        setListMenu(data.data.listMenu);
        setCourse(data.data.course);
        setCustomerCourse(data.data.customerCourse);
    };
    useEffect(() => {
        if (courseId) {
            fetchData();
        }
    }, [courseId]);

    const showHtml = () => {
        let xhtml = null;
        if (listMenu.length > 0) {
            xhtml = listMenu.map((item, index) => {
                return (
                    <StudyLessonMenuItem
                        dataItem={item}
                        courseItem={course}
                        customerCourseItem={customerCourse}
                        key={index}
                    />
                );
            });
        }
        return xhtml;
    };
    return (
        <div className="sidebar">
            <h4 className="course-details__top-name">Nội dung khóa học</h4>
            <div
                className="accrodion-grp faq-one-accrodion"
                data-grp-name="faq-one-accrodion"
            >
                {showHtml()}
            </div>
        </div>
    );
}

document.querySelectorAll('react-study-lesson-menu').forEach((domContainer) => {
    const courseId = parseInt(domContainer.dataset.courseid, 10);
    const root = ReactDOM.createRoot(domContainer);
    root.render(creElm(StudyLessonMenu, { courseId }));
});
