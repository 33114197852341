const CourseDirectGroupButton = ({ dataItem, lessonId }) => {
    const [showTestPopup, setShowTestPopup] = useState(false);

    const onComplete = async () => {
        swalShowLoading('Đang kiểm tra...');
        const { status, data } = await _lessonApis.checkTest({ lessonId });
        if (status !== 200) {
            mySwal(SWAL_CONST.TYPE.ERR, `Xảy ra lỗi: ${data.msg}`);
            return;
        }
        if (!data.isTest) {
            window.location.href = data.nextLessonUrl;
        }
        swalCloseLoading();
        setShowTestPopup(data.isTest);
    };

    return (
        <Fragment>
            <div className="comment-form col-12">
                {showTestPopup ? (
                    <DoTestPopup isShow={showTestPopup} lessonId={lessonId} />
                ) : null}

                <div className="row">
                    <div className="col-4">
                        <CoursePrevButton link={dataItem.prevLink} />
                    </div>
                    <div className="col-4 text-center">
                        <LessonCompleteButton
                            dataItem={dataItem}
                            onClick={onComplete}
                        />
                    </div>
                    <div className="col-4 text-right">
                        <LessonNextButton link={dataItem.nextLink} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
