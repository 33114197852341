const Rating = (props) => {
    useEffect(() => {
        if (props.id) {
            new StarRating(`#${props.id}`, {
                tooltip: 'Vui lòng đánh giá',
            });
        }
    }, [props.id]);

    return (
        <Fragment>
            <select {...props}>
                <option value="">Vui lòng đánh giá</option>
                <option value="5">Tuyệt vời</option>
                <option value="4">Rất tốt</option>
                <option value="3">Tốt</option>
                <option value="2">Bình thường</option>
                <option value="1">Tệ</option>
            </select>
        </Fragment>
    );
};
